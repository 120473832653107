import Response from "@data-access/response/response";
import { NUser } from "../../interfaces/components/user";
import BaseApi from "./baseApi.service";

export class UserService extends BaseApi {
    private static instance: UserService;
    constructor() {
        super("market");
    }

    private readonly END_POINT = {
        auth: "v1/auth",
        user: "v1/user",
        userAddresses: "v1/user/addresses",
        userAddress: "v1/user/address",
    };

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    public async login(userToken: string): Promise<NUser.ILogin | null> {
        const result = await this.post<Response<NUser.ILogin>>(
            `${this.END_POINT.auth}/login`,
            {
                userToken: userToken,
            },
        );
        return result?.data?.data ?? null;
    }

    public async getAddresses(
        userId: string,
    ): Promise<NUser.IUserAddress[] | undefined> {
        const url = `${this.END_POINT.userAddresses}/${userId}`;
        const result = await this.get<Response<NUser.IUserAddress[]>>(url);
        return result?.data?.data;
    }

    public async updateAddress(
        id: string,
        address: NUser.IUserAddress,
    ): Promise<NUser.IUserAddress | undefined> {
        const url = `${this.END_POINT.userAddress}/${id}`;
        const result = await this.put<Response<NUser.IUserAddress>>(
            url,
            address,
        );
        return result?.data?.data;
    }

    public async addAddress(
        address: NUser.IUserAddress,
    ): Promise<NUser.IUserAddress | undefined> {
        const url = `${this.END_POINT.userAddress}`;
        const result = await this.post<Response<NUser.IUserAddress>>(
            url,
            address,
        );
        return result?.data?.data;
    }

    public async deleteAddress(id: string): Promise<boolean | undefined> {
        const url = `${this.END_POINT.userAddress}/${id}`;
        const result = await this.delete<Response<boolean>>(url);
        return result?.data?.data;
    }
}
