"use client";

import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import store from "@redux/store";
import Connector from "../common/mqtt/Connector";
import apolloClient from "@services/apollo/apollo-client";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Script from "next/script";
import "../app/i18n/client";
import useCheckMobileScreen from "./use-check-mobile-screen";
import { MasterContainer } from "../components/layouts/market";
import { CheckoutContextProvider } from "@components/modules/checkout/checkout.context";
import { SWRConfig } from "swr";

import { env } from "next-runtime-env";

function Providers({
    children,
    params: { lng },
}: {
    children: React.ReactNode;
    params: Record<string, unknown>;
}) {
    const client = new QueryClient();
    const pageProps = {
        lng: (lng ?? "en") as string,
        isMobile: useCheckMobileScreen(),
    };
    return (
        <QueryClientProvider client={client}>
            <SWRConfig
                value={{
                    revalidateOnFocus: false,
                    revalidateOnReconnect: false,
                }}
            >
                <ReactQueryStreamedHydration>
                    <Provider store={store}>
                        <Connector
                            brokerUrl={env("NEXT_PUBLIC_MQTT_URL") ?? ""}
                            options={{ keepalive: 60 }}
                        >
                            <ApolloProvider client={apolloClient}>
                                <MasterContainer {...pageProps}>
                                    <>
                                        <Script
                                            src={`https://www.googletagmanager.com/gtag/js?id=${
                                                env(
                                                    "NEXT_PUBLIC_GA_MEASUREMENT_ID",
                                                ) ?? ""
                                            }`}
                                        />
                                        <Script id="google-analytics">
                                            {`
                                                window.dataLayer = window.dataLayer || [];
                                                function gtag(){dataLayer.push(arguments);}
                                                gtag('js', new Date());
                                        
                                                gtag('config', '${
                                                    env(
                                                        "NEXT_PUBLIC_GA_MEASUREMENT_ID",
                                                    ) ?? ""
                                                }');
                                            `}
                                        </Script>
                                        <CheckoutContextProvider>
                                            {children}
                                        </CheckoutContextProvider>
                                    </>
                                </MasterContainer>
                            </ApolloProvider>
                        </Connector>
                    </Provider>
                </ReactQueryStreamedHydration>
            </SWRConfig>
        </QueryClientProvider>
    );
}

export default Providers;
