import { getCookie, removeCookies } from "cookies-next";
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../../common/constant";
import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { env } from "next-runtime-env";

const getToken = () => {
    const accessToken = getCookie(TOKEN_KEY) as string;
    return accessToken ? `Bearer ${accessToken}` : "";
};

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length && graphQLErrors[0].message === "Unauthorized") {
        localStorage.clear();
        sessionStorage.clear();
        removeCookies("kcIdToken");
        removeCookies("kcToken");
        removeCookies(TOKEN_KEY);
        removeCookies(REFRESH_TOKEN_KEY);
        location.reload();
    }
});
const httpLink = new HttpLink({ uri: env("NEXT_PUBLIC_API_GRAPHQL_URL") });

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: getToken(),
        },
    };
});

const appLink = from([errorLink, authLink.concat(httpLink)]);

const apolloClient = new ApolloClient({
    link: appLink,
    cache: new InMemoryCache(),
});

export default apolloClient;
