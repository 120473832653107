import { REPORT_TOKEN } from "@common/constant";
import { IReport } from "@data-access/models/report.model";
import Response from "@data-access/response/response";
import { getCookie, removeCookies, setCookie } from "cookies-next";
import BaseApi from "./baseApi.service";
import jwtDecode from "jwt-decode";

export type ReportLoginDto = {
    username: string;
    password: string;
};

export class StatisticService extends BaseApi {
    private static instance: StatisticService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (StatisticService.store) {
            customHeader["x-tenant-slug"] = StatisticService.store;
        }

        const reportToken = getCookie(REPORT_TOKEN)?.toString();
        if (reportToken)
            customHeader["Authorization"] = `Bearer ${reportToken}`;

        return customHeader;
    };

    constructor(store?: string) {
        super("report");
        StatisticService.store = store;
    }

    public static getInstance(): StatisticService {
        if (!StatisticService.instance) {
            StatisticService.instance = new StatisticService();
        }
        return StatisticService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new StatisticService(store);
    }

    private readonly END_POINT = "v1";

    public addTraffic = async (sessionId: string) => {
        const { data } = await this.post<any>(
            `${this.END_POINT}/statistic/add-traffic`,
            { sessionId },
            {},
        );

        console.log(data);

        return data?.data;
    };

    public login = async (
        request: ReportLoginDto,
    ): Promise<string | undefined> => {
        const { data } = await this.post<Response<{ token: string }>>(
            `${this.END_POINT}/auth/login`,
            request,
            {},
        );

        const token = data?.data.token;
        if (token) {
            const decodedToken: any = jwtDecode(token);

            if (decodedToken.exp) {
                const expirationTime = new Date(0);
                expirationTime.setUTCSeconds(decodedToken.exp);
                setCookie(REPORT_TOKEN, token, { expires: expirationTime });
            }

            return data?.data.token;
        } else {
            removeCookies(REPORT_TOKEN);
            return undefined;
        }
    };

    public async getStatistic(): Promise<Response<IReport> | null> {
        const result = await this.get<Response<IReport>>(
            `${this.END_POINT}/statistic`,
            {},
            this.customHeader(),
        );

        return result?.data;
    }
}
