"use client";

import { Layout } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import { PropsWithChildren, useEffect } from "react";
import { cartSelector } from "../../../redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { PageProps } from "@interfaces/components/page-props";
import { guestSelector } from "../../../redux/slices/guestSlice";
import { setUser, userSelector } from "../../../redux/slices";
import { getSessionValue } from "../../../utils";
import { USER_KEYS } from "../../../common/constant";
import { UserService } from "../../../services/api/user.service";
import { NUser } from "../../../interfaces/components/user";
import { setCookie } from "cookies-next";
import { StatisticService } from "@services/api/statistic.service";

const statisticService = StatisticService.getInstance();

export const MasterContainer: React.FC<PropsWithChildren<PageProps>> = ({
    children,
}) => {
    const { Content } = Layout;
    const dispatch = useAppDispatch();
    const searchParams = useSearchParams();
    const guest = useAppSelector(guestSelector);
    const user = useAppSelector(userSelector);
    const cart = useAppSelector(cartSelector);
    const router = useRouter();
    useEffect(() => {
        document?.addEventListener(
            "contextmenu",
            function (e) {
                e?.preventDefault();
            },
            false,
        );
    }, []);

    const userToken = searchParams?.get("access_token") as string;

    const login = async () => {
        const userSession = getSessionValue<NUser.IUser>(USER_KEYS);
        const service = UserService.getInstance();
        if (userToken && !userSession) {
            const data = await service.login(userToken);
            const user = data?.user;
            if (user) {
                user.accessToken = data.token;
                user.integrationToken = userToken;
                setCookie("access_token", user.accessToken);
                dispatch(setUser(user));
            }
        }
    };

    useEffect(() => {
        login();
    }, [userToken]);

    useEffect(() => {
        const userSession = getSessionValue<NUser.IUser>(USER_KEYS);

        if (userSession) {
            dispatch(setUser(userSession));
        }

        let sessionId = sessionStorage.getItem("sessionId");
        if (sessionId) return;

        sessionId = new Date().getTime().toString();
        sessionStorage.setItem("sessionId", sessionId);

        statisticService.addTraffic(sessionId);
    }, []);

    // useEffect(() => {
    //     const userSession = getSessionValue<IGuest>(USER_KEYS);
    //     if (!userSession) {
    //     }

    //     const guestSession = getSessionValue<IGuest>(GUEST);
    //     if (!guestSession) {
    //         const accessToken = getCookie(TOKEN_KEY) as string;
    //         if (accessToken) saveUserInfo(accessToken);
    //     }

    //     const cartSession = getSessionValue<NCart.ICart>(CART);

    //     const pathName = window.location.pathname;
    //     const isCheckoutComplete = CHECKOUT_COMPLETE_PATHS.includes(pathName);
    //     if (isCheckoutComplete) {
    //         dispatch(removeCart());
    //     } else {
    //         if (cartSession) dispatch(setCart(cartSession));
    //     }

    //     if (isReInitCart) {
    //         dispatch(removeCart());
    //     }
    // }, []);

    const isShowLoading = false;

    return (
        <div className="min-h-screen flex flex-col overflow-hidden">
            {isShowLoading ? (
                <div className="h-screen flex items-center">
                    <img
                        width={100}
                        style={{ margin: "auto", display: "block" }}
                        src="/images/loading.svg"
                        alt="selected-location"
                    />
                </div>
            ) : (
                <Layout
                    style={{
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Content>
                        <main className={`w-full m-auto`}>{children}</main>
                    </Content>
                </Layout>
            )}
        </div>
    );
};
